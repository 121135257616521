<template>
  <div class="loading-layout">
    <a-loader />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoadingLayout'
});
</script>

<style lang="scss" scoped>
.loading-layout {
  position: fixed;
  inset: 0;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-lower);
}
</style>
