export enum LocalStoreKey {
  SESSION_ACCESS = 'token',
  THEME = 'ui_theme'
}

export enum BookingType {
  desk = 'desk',
  parking = 'parking'
}

export enum BookingTypeDisplayName {
  desk = 'Офис',
  parking = 'Парковка'
}

export enum HistoryType {
  current = 'current',
  past = 'past'
}

export const EMAIL_CONTACT = 'sales@gpmdata.ru';
export const PATH_TO_POLICY = 'https://path.to/offer.pdf';

export * from './RouteName';
export * from './SomethingWrongCode';
