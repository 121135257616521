import { defineStore } from 'pinia';

import { useNotify, type Optional } from '@altai/core';

import type { BookingType } from '@/constants';
import { useLots } from '@/hooks';

import type { Lot, LotsState } from './types';

export const useLotsStore = defineStore({
  id: 'lots',

  state: (): LotsState => ({
    lots: null,
    myBookings: null,
    allBookings: null
  }),

  getters: {
    getLotModelById: state => {
      return (id: string): Optional<Lot> =>
        state.lots?.find(lot => lot.id === id);
    },
    getLots: state => {
      return (type: BookingType): Lot[] =>
        state.lots?.filter(lot => lot.type === type) ?? [];
    }
  },

  actions: {
    setState({ lots, allBookings, myBookings }: Partial<LotsState>): void {
      if (lots) this.lots = lots;
      if (myBookings) this.myBookings = myBookings;
      if (allBookings) this.allBookings = allBookings;
    },
    async updateLots(): Promise<void> {
      try {
        const lots = await useLots().update();
        if (lots.data) {
          this.lots = lots.data;
        }
      } catch {
        useNotify().error({ content: 'Не удалось обновить список мест' });
      }
    }
  }
});
