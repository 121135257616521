import type { UseFetchReturn } from '@/hooks/createFetch';
import { useRequest } from '@/services';

import type { GetAdminLotsParams, GetAdminLotsResult } from './types';

export const getAdminLots = (): UseFetchReturn<
  GetAdminLotsResult,
  never,
  GetAdminLotsParams
> => {
  return useRequest({
    path: '/admin/lots',
    method: 'GET'
  });
};
