<template>
  <transition
    name="layout-transition"
    :duration="{ enter: 0, leave: 500 }"
    @after-leave="afterLeave"
  >
    <loading-layout v-if="isLoadingShown" />
  </transition>

  <component :is="currentLayout" />
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  computed,
  watch,
  nextTick,
  onMounted,
  onUnmounted,
  type Component
} from 'vue';
import { useRouter, useRoute } from 'vue-router';

import type { Nullable } from '@altai/core';

import { RouteName } from '@/constants';
import { BaseLayout, EmptyLayout, LoadingLayout } from '@/layouts';
import { useProfileStore } from '@/store';

import type { AppInstance } from './types';

export default defineComponent({
  name: 'App',

  components: {
    BaseLayout,
    EmptyLayout,
    LoadingLayout
  },

  setup(): AppInstance {
    const profile = useProfileStore();
    const router = useRouter();
    const route = useRoute();

    const isLoadingShown = ref<boolean>(true);

    const currentLayout = computed<Nullable<Component>>(() => {
      if (isLoadingShown.value) return null;

      switch (route.meta.layout) {
        case 'dynamic':
          return profile.isUserExist ? BaseLayout : EmptyLayout;

        case 'empty':
          return EmptyLayout;

        default:
          return BaseLayout;
      }
    });

    document.documentElement.classList.add('gd-html_is_loading');

    const afterLeave = (): void => {
      document.documentElement.classList.remove('gd-html_is_loading');
      document.documentElement.classList.add('gd-html_is_loaded');
    };

    const handleLogoutEvent = (query?: Record<string, string>): void => {
      profile.logout();
      router.push({ name: RouteName.AUTH_SIGN_IN, query });
    };

    watch(
      route,
      async ({ name }) => {
        await nextTick();
        isLoadingShown.value = !name;
      },
      { immediate: true }
    );

    onMounted(() => {
      // eventHub.on('logout', handleLogoutEvent);
    });

    onUnmounted(() => {
      // eventHub.off('logout', handleLogoutEvent);
      // TODO: убрать когда раскомментится предыдущая
      handleLogoutEvent();
    });

    return {
      isLoadingShown,
      currentLayout,
      afterLeave
    };
  }
});
</script>

<style lang="scss">
html {
  min-height: 100%;
}

.gd-html {
  &_is_loading {
    overflow: hidden;
  }
}
</style>

<style lang="scss" scoped>
.layout-transition {
  &-leave {
    &-active {
      transition: opacity 1s ease;
    }

    &-to {
      opacity: 0;
    }
  }
}
</style>
