import { type Nullable } from '@altai/core';

import { LocalStoreKey } from '@/constants';
import { createFetch } from '@/hooks/createFetch';
import { useProfileStore } from '@/store';

const BASE_URL = `${import.meta.env.VITE_API_ENDPOINT}/api/v1`;

export const useRequest = createFetch({
  baseUrl: BASE_URL,
  getBearerToken: async (): Promise<Nullable<string>> => {
    let token = localStorage.getItem(LocalStoreKey.SESSION_ACCESS);
    const profile = useProfileStore();
    const keycloak = profile.keycloak;
    if (keycloak?.isTokenExpired()) {
      try {
        const refreshed = await keycloak?.updateToken(5); // minValidity
        if (refreshed) {
          token = keycloak?.token ?? '';
          localStorage.setItem(LocalStoreKey.SESSION_ACCESS, token);
        } else {
          keycloak.clearToken();
          return null;
        }
      } catch (err) {
        keycloak.login();
        return null;
      }
    }

    return token;
  }
});

export * from './lots';
export * from './users';
export * from './admin';
