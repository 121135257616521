import type { App } from 'vue';

import GD_UI_DS, { ru as langRu } from '@altai/core';
import type { GlobalConfig } from '@altai/core';

import AContainer from '@/components/AContainer';

import '@altai/fonts';
import '@altai/theme-base';

import '@/styles/theme-day.scss';
import '@/styles/theme-night.scss';

const setupAppComponents = (app: App<Element>): void => {
  const globalConfig: Partial<GlobalConfig> = { locale: langRu };
  app.use(GD_UI_DS, globalConfig);
  app.component('AContainer', AContainer);
};

export { setupAppComponents };
