import type { RouteRecordRaw, RouteComponent } from 'vue-router';

import { RouteName } from '@/constants';

const BookingPage = (): Promise<RouteComponent> => import('@/pages/Booking');

export const bookingRoutes: RouteRecordRaw = {
  path: '/booking',
  name: RouteName.BOOKING,
  component: BookingPage,
  meta: {
    breadcrumb: 'Бронирование'
  }
};
