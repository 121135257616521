import { defineStore } from 'pinia';

import type { UsersState } from './types';

export const useUsersStore = defineStore({
  id: 'users',

  state: (): UsersState => ({
    users: null
  }),

  actions: {
    setState({ users }: Partial<UsersState>): void {
      if (users) this.users = users;
    }
  }
});

export type { User } from './types';
