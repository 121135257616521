import type { RouteRecordRaw, RouteComponent } from 'vue-router';

import { RouteName } from '@/constants';

const AuthenticationPage = (): Promise<RouteComponent> =>
  import('@/pages/Authentication');
const SignInPage = (): Promise<RouteComponent> =>
  import('@/pages/Authentication/SignIn');
const PasswordSettingPage = (): Promise<RouteComponent> =>
  import('@/pages/Authentication/PasswordSetting');
const PasswordRecoveryPage = (): Promise<RouteComponent> =>
  import('@/pages/Authentication/PasswordRecovery');

export const authenticationRoutes: RouteRecordRaw = {
  path: '/auth',
  component: AuthenticationPage,
  name: RouteName.AUTH,
  redirect: { name: RouteName.AUTH_SIGN_IN },
  children: [
    {
      name: RouteName.AUTH_SIGN_IN,
      path: 'signin',
      component: SignInPage,
      meta: { layout: 'empty' }
    },

    {
      name: RouteName.AUTH_PASSWORD_RECOVERY,
      path: 'password/recovery',
      component: PasswordRecoveryPage,
      meta: { layout: 'empty' }
    },

    {
      name: RouteName.AUTH_PASSWORD_SETTING,
      path: 'password/setting',
      component: PasswordSettingPage,
      meta: { layout: 'empty' }
    }
  ]
};
