<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="33"
    viewBox="0 0 36 33"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 0.6073H36L18.001 31.6585L10.2953 18.4588L13.4229 16.6435L17.9899 24.4667L29.7285 4.21571H6.25328L10.7532 12.0241L7.61544 13.8219L0 0.6073Z"
      fill="white"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GpmDataLogo'
});
</script>
