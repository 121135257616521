import * as Sentry from '@sentry/vue';
import { createApp } from 'vue';

import { App } from './components/App';
import { setupAppComponents } from './hooks';
import { setupRouter } from './router';
import { setupStore } from './store';

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const PROJECT_NAME = 'gd-booking';

const bootstrap = async (): Promise<void> => {
  const app = createApp(App);

  setupStore(app);
  setupAppComponents(app);

  const router = setupRouter(app);
  await router.isReady();

  Sentry.init({
    app,
    dsn: SENTRY_DSN,
    environment: import.meta.env.MODE,
    release: `${PROJECT_NAME}@${import.meta.env.VITE_PACKAGE_VERSION}`,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration()
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/keycloak-dev.gpmdata\.tech/,
      /^https:\/\/sso.gpmdata.tech/,
      'https://gd-booking-backend-dev.k8s01.dev.gpmdata.tech/api',
      'https://booking.gpmdata.ru/api/'
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  app.mount('#app');
};

bootstrap();
