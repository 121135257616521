import type { RouteLocationRaw, Router } from 'vue-router';

import { useProgressIndicator, type Nillable } from '@altai/core';

import { RouteName } from '@/constants';
import { userIsAdmin } from '@/helpers';
import { useKeycloak } from '@/hooks';
import { useProfileStore } from '@/store';

const checkPublicPaths = (name: Nillable<string>): boolean => {
  if (!name) return false;

  const publicRouteList: string[] = [
    RouteName.AUTH,
    RouteName.AUTH_SIGN_IN,
    RouteName.AUTH_PASSWORD_SETTING,
    RouteName.AUTH_PASSWORD_RECOVERY,
    RouteName.NOT_FOUND_PAGE
  ];

  return publicRouteList.includes(name);
};

const checkAdminPaths = (name: Nillable<string>): boolean => {
  if (!name) return false;
  const adminRouteList: string[] = [
    RouteName.ADMIN,
    RouteName.ADMIN_BOOKINGS_ALL,
    RouteName.ADMIN_BOOKINGS_LIST,
    RouteName.ADMIN_BOOKINGS_NEW,
    RouteName.ADMIN_LOTS,
    RouteName.ADMIN_CARDS
  ];

  return Boolean(adminRouteList.includes(name));
};

export const createPermissionGuard = (router: Router): void => {
  const profile = useProfileStore();
  const progressBar = useProgressIndicator();
  router.beforeEach(async ({ name, fullPath, redirectedFrom }) => {
    progressBar?.start();
    if (redirectedFrom) {
      progressBar?.done();
    }

    const signInRoute: RouteLocationRaw = { name: RouteName.AUTH_SIGN_IN };
    const forbiddenRoute: RouteLocationRaw = { name: RouteName.FORBIDDEN_PAGE };
    if (name !== RouteName.AUTH_SIGN_IN && name !== RouteName.HOME) {
      signInRoute.query = { redirect: fullPath };
    }

    if (checkPublicPaths(String(name))) return true;

    if (profile.user) {
      if (checkAdminPaths(String(name))) {
        return userIsAdmin(profile.user.roles) ? true : forbiddenRoute;
      }

      return true;
    }

    try {
      const { isAuthenticated, keycloak } = await useKeycloak();
      const user = await keycloak.loadUserProfile();
      const storedUser = {
        ...user,
        roles: keycloak.tokenParsed?.resource_access?.booking?.roles ?? []
      };
      if (isAuthenticated && keycloak.token && keycloak.refreshToken) {
        profile.setState({
          keycloak,
          user: storedUser,
          session: {
            token: keycloak.token,
            refreshToken: keycloak.refreshToken
          }
        });

        if (checkAdminPaths(String(name))) {
          return userIsAdmin(storedUser.roles) ? true : forbiddenRoute;
        }

        return true;
      }

      return signInRoute;
    } catch {
      return signInRoute;
    }
  });

  router.afterEach(async () => {
    progressBar?.done();
  });
};
