import type { RouteRecordRaw, RouteComponent } from 'vue-router';

import { RouteName } from '@/constants';

const ProfilePage = (): Promise<RouteComponent> => import('@/pages/Profile');

export const profileRoutes: RouteRecordRaw = {
  path: '/profile',
  component: ProfilePage,
  name: RouteName.PROFILE,
  meta: {
    breadcrumb: 'Настройки профиля'
  }
};
