import { tryOnUnmounted } from '@vueuse/core';
import { debounce } from 'lodash-es';

import { prepareQueryParams } from '@altai/core';

import { ResponseBodyType } from './constants';
import type {
  RequestBody,
  QueryParams,
  Params,
  CreateFetchOptions,
  CreateFetchReturn,
  ExecuteOptions,
  ExecuteReturn,
  UseFetchOptions,
  UseFetchReturn
} from './types';

const prepareUrl = (rawUrl: string, params?: Params): string => {
  if (!params) return rawUrl;

  return Object.entries(params).reduce(
    (url, [key, value]) => url.replace(`:${key}`, String(value)),
    rawUrl
  );
};

const castType =
  <R>(promisedResponse: Promise<Response>, type: ResponseBodyType) =>
  async (): Promise<R> => {
    const response = await promisedResponse;
    const body = await response[type]();

    return new Promise((resolve, reject) => {
      if (response.ok) resolve(body);
      else reject(body);
    });
  };

export const createFetch = ({
  baseUrl,
  getBearerToken
}: CreateFetchOptions): CreateFetchReturn => {
  const useFetch = <
    R,
    B extends RequestBody,
    Q extends QueryParams,
    P extends Params = never
  >(
    options: UseFetchOptions
  ): UseFetchReturn<R, B, Q, P> => {
    let innerAbortController: AbortController =
      options.abortController ?? new AbortController();

    const fetchInit: RequestInit = {
      method: options.method ?? 'GET'
    };

    const requestUrl = options.path
      ? `${baseUrl}/${options.path.replace(/^\//, '')}`
      : baseUrl;

    const execute = ({
      body,
      queryParams,
      params
    }: ExecuteOptions<B, Q, P> = {}): ExecuteReturn<R> => {
      fetchInit.signal = innerAbortController.signal;
      fetchInit.headers = {};

      if (body) {
        if (body instanceof FormData) {
          fetchInit.headers['content-type'] = 'multipart/form-data';
          fetchInit.body = body;
        } else if (body instanceof Object) {
          fetchInit.headers['content-type'] = 'application/json';
          fetchInit.body = JSON.stringify(body);
        } else {
          fetchInit.body = body;
        }
      }

      let url = prepareUrl(requestUrl, params);
      const preparedQP = prepareQueryParams(queryParams);
      if (preparedQP) url += `?${preparedQP}`;

      const response = Promise.resolve()
        .then(() => getBearerToken?.())
        .then(bearerToken =>
          fetch(url, {
            ...fetchInit,
            headers: {
              ...fetchInit.headers,
              ...(bearerToken ? { authorization: `Bearer ${bearerToken}` } : {})
            }
          })
        );

      return {
        native: () => response,
        json: castType(response, ResponseBodyType.json),
        text: castType(response, ResponseBodyType.text),
        blob: castType(response, ResponseBodyType.blob),
        formData: castType(response, ResponseBodyType.formData),
        arrayBuffer: castType(response, ResponseBodyType.arrayBuffer)
      };
    };

    const abort = (abortController?: AbortController): void => {
      innerAbortController.abort();
      innerAbortController = abortController ?? new AbortController();
    };

    tryOnUnmounted(() => {
      innerAbortController.abort();
    });

    return {
      execute,
      debouncedExec: debounce(execute, options.debounceMs),
      abort
    };
  };

  return useFetch;
};

export type { UseFetchReturn };
