import { defineStore } from 'pinia';

import { LocalStoreKey } from '@/constants';

import type { ProfileState } from './types';

export const useProfileStore = defineStore({
  id: 'profile',

  state: (): ProfileState => ({
    user: null,
    session: null,
    keycloak: null
  }),

  getters: {
    isUserExist(): boolean {
      return Boolean(this.user?.id);
    }
  },

  actions: {
    setState({ user, session, keycloak }: Partial<ProfileState>): void {
      if (keycloak) this.keycloak = keycloak;
      if (user) this.user = user;
      if (session) {
        this.session = session;
        localStorage.setItem(LocalStoreKey.SESSION_ACCESS, session.token);
      }
    },

    logout(): void {
      localStorage.removeItem(LocalStoreKey.SESSION_ACCESS);
      this.$reset();
    }
  }
});
