export enum RouteName {
  HOME = 'HOME',

  PROFILE = 'PROFILE',

  AUTH = 'AUTH',
  AUTH_SIGN_IN = 'AUTH_SIGN_IN',
  AUTH_PASSWORD_SETTING = 'AUTH_PASSWORD_SETTING',
  AUTH_PASSWORD_RECOVERY = 'AUTH_PASSWORD_RECOVERY',

  BOOKING = 'BOOKING',
  ADMIN = 'ADMIN',
  ADMIN_BOOKINGS_ALL = 'ADMIN_BOOKINGS_ALL',
  ADMIN_BOOKINGS_LIST = 'ADMIN_BOOKINGS_LIST',
  ADMIN_BOOKINGS_NEW = 'ADMIN_BOOKINGS_NEW',
  ADMIN_PLACES = 'ADMIN_PLACES',
  ADMIN_LOTS = 'ADMIN_LOTS',
  ADMIN_CARDS = 'ADMIN_CARDS',

  NOT_FOUND_PAGE = 'NOT_FOUND_PAGE',
  FORBIDDEN_PAGE = 'FORBIDDEN_PAGE'
}
