import type { RouteRecordRaw, RouteComponent } from 'vue-router';

import { RouteName } from '@/constants';

import { adminRoutes } from './admin';
import { authenticationRoutes } from './auth';
import { bookingRoutes } from './booking';
import { profileRoutes } from './profile';

const NotFoundPage = (): Promise<RouteComponent> => import('@/pages/NotFound');

const ForbiddenPage = (): Promise<RouteComponent> =>
  import('@/pages/Forbidden');

export const routes: RouteRecordRaw[] = [
  {
    name: RouteName.HOME,
    path: '/',
    redirect: { name: RouteName.BOOKING }
  },

  authenticationRoutes,
  profileRoutes,
  bookingRoutes,
  adminRoutes,

  {
    name: RouteName.NOT_FOUND_PAGE,
    path: '/:pathMatch(.*)*',
    component: NotFoundPage
  },
  {
    name: RouteName.FORBIDDEN_PAGE,
    path: '/403',
    component: ForbiddenPage
  }
];
