import { useNotify, type Optional } from '@altai/core';

import { userIsAdmin } from '@/helpers';
import { getAdminLots, getLots } from '@/services';
import type { GetAdminLotsParams } from '@/services/admin/getAdminLots/types';
import type {
  GetLotsParams,
  GetLotsResult
} from '@/services/lots/getLots/types';
import { useProfileStore } from '@/store';

import type { UseAdminHook } from './type';

export const useLots = (): UseAdminHook => {
  const profile = useProfileStore();
  const isAdmin = userIsAdmin(profile.user?.roles ?? []);

  const lotsRequest = isAdmin ? getAdminLots() : getLots();
  const notify = useNotify();

  return {
    update: async (
      queryParams?: Optional<GetAdminLotsParams & GetLotsParams>
    ): Promise<GetLotsResult> => {
      try {
        const lotsData = await lotsRequest.execute({ queryParams }).json();
        return lotsData;
      } catch {
        notify.error({
          content: 'Произошла ошибка при получении списка доступных мест'
        });

        return Promise.reject();
      }
    }
  };
};
