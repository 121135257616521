<template>
  <div class="a-container">
    <div
      v-if="isTable"
      class="a-container__table-inner"
    >
      <slot />
    </div>
    <div
      v-else
      class="a-container__inner"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AContainer',

  props: {
    isTable: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="scss" scoped>
.a-container {
  width: 100%;
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;

  &__inner {
    padding-right: 32px;
    padding-left: 32px;
  }
}
</style>
