import type { UseFetchReturn } from '@/hooks/createFetch';
import { useRequest } from '@/services';

import type { GetLotsParams, GetLotsResult } from './types';

export const getLots = (): UseFetchReturn<
  GetLotsResult,
  never,
  GetLotsParams
> => {
  return useRequest({
    path: '/lots',
    method: 'GET'
  });
};
