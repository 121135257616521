<template>
  <div class="base-layout">
    <div class="base-layout__aside">
      <a-sidebar
        :menu-items="menuItems"
        :logo="GpmDataLogo"
        name="Booking"
      />
    </div>

    <div class="base-layout__content">
      <div class="base-layout__breadcrumbs">
        <a-breadcrumbs :route="routes" />
      </div>
      <div class="base-layout__inner">
        <router-view />
      </div>
    </div>
    <a-float-bar
      class="float-bar"
      :email="user?.email"
      @open-profile="handleOpenProfileClick"
      @logout="handleLogoutClick"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from 'vue';
import { useRoute, type RouteLocationMatched, useRouter } from 'vue-router';

import {
  SidebarMenuItemType,
  IconCalendarOutlined,
  IconUserDoubleFilled,
  type ASidebarProps,
  IconGearOutlined,
  IconParkinFilled
} from '@altai/core';

import gpmdataLogo from '@/assets/gpmdata-logo.svg';
import { GpmDataLogo } from '@/components/GpmDataLogo';
import { RouteName } from '@/constants';
import { userIsAdmin } from '@/helpers';
import { useLotsStore, useProfileStore } from '@/store';

export default defineComponent({
  name: 'BaseLayout',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const profile = useProfileStore();
    const user = profile.user;
    const lotsStore = useLotsStore();

    const menuItems = computed<ASidebarProps['menuItems']>(() => {
      const items: ASidebarProps['menuItems'] = [
        {
          type: SidebarMenuItemType.link,
          title: 'Бронирование',
          icon: IconCalendarOutlined,
          active: route.name === RouteName.BOOKING,
          props: {
            to: {
              name: RouteName.BOOKING
            }
          }
        }
      ];

      if (user?.roles && userIsAdmin(user.roles)) {
        items.push({
          type: SidebarMenuItemType.group,
          title: 'Администрирование',
          children: [
            {
              title: 'Все брони',
              type: SidebarMenuItemType.link,
              icon: IconUserDoubleFilled,
              active:
                route.name === RouteName.ADMIN_BOOKINGS_LIST ||
                route.name === RouteName.ADMIN_BOOKINGS_ALL ||
                route.name === RouteName.ADMIN_BOOKINGS_NEW,
              props: { to: { name: RouteName.ADMIN_BOOKINGS_ALL } }
            },
            {
              title: 'Управление местами',
              type: SidebarMenuItemType.link,
              icon: IconGearOutlined,
              active: route.name === RouteName.ADMIN_PLACES,
              props: { to: { name: RouteName.ADMIN_PLACES } }
            },
            {
              title: 'Парковочная карта',
              type: SidebarMenuItemType.link,
              icon: IconParkinFilled,
              active: route.name === RouteName.ADMIN_CARDS,
              props: { to: { name: RouteName.ADMIN_CARDS } }
            }
          ]
        });
      }

      return items;
    });

    const routes = computed<RouteLocationMatched[]>(() => route.matched);

    const handleLogoutClick = async (): Promise<void> => {
      const redirectPath = router.resolve({
        name: RouteName.AUTH_SIGN_IN
      }).path;

      profile.keycloak?.logout({
        redirectUri: document.location.origin + redirectPath
      });
    };

    const handleOpenProfileClick = (): void => {
      router.push({ name: RouteName.PROFILE });
    };

    onMounted(async () => {
      lotsStore.updateLots();
    });

    return {
      user,
      menuItems,
      routes,
      handleOpenProfileClick,
      handleLogoutClick,
      gpmdataLogo,
      GpmDataLogo
    };
  }
});
</script>

<style lang="scss" scoped>
.float-bar {
  position: fixed;
  top: 24px;
  right: 32px;
  z-index: 99;
  height: 40px;
  background-color: var(--background-higher);
  border-radius: 8px;
  box-shadow: var(--box-shadow-400);
}

.base-layout {
  display: grid;
  grid-template-columns: auto 1fr;
  min-width: 1280px;
  min-height: 100%;

  &__aside {
    position: sticky;
    top: 0;
    z-index: 11;
    height: 100%;
    max-height: 100vh;

    html.gd-html_is_loaded & {
      transform: translateX(0);
    }
  }

  &__breadcrumbs {
    margin: 32px;
  }

  &__content {
    z-index: 10;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    background-color: var(--background-lower);
    opacity: 0;
    transition:
      transform 0.5s ease 0.15s,
      opacity 0.5s ease 0.15s;
    transform: translateY(50px);

    html.gd-html_is_loaded & {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
