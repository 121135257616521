import type { RouteRecordRaw, RouteComponent } from 'vue-router';

import { RouteName } from '@/constants';

const AdminPage = (): Promise<RouteComponent> => import('@/pages/Admin');

const AdminBookingPage = (): Promise<RouteComponent> =>
  import('@/pages/Admin/AllBookings');

const AdminBookingPageNew = (): Promise<RouteComponent> =>
  import('@/pages/Admin/AllBookings/New');
const AdminBookingPageList = (): Promise<RouteComponent> =>
  import('@/pages/Admin/AllBookings/List');
const AdminBookingParkingCards = (): Promise<RouteComponent> =>
  import('@/pages/Admin/AllBookings/ParkingCards');

const PlacesPage = (): Promise<RouteComponent> =>
  import('@/pages/Admin/Places');

export const adminRoutes: RouteRecordRaw = {
  path: '/admin',
  component: AdminPage,
  name: RouteName.ADMIN,
  redirect: { name: RouteName.ADMIN_BOOKINGS_ALL },
  meta: {
    breadcrumb: 'Администрирование'
  },
  children: [
    {
      path: 'booking',
      component: AdminBookingPage,
      name: RouteName.ADMIN_BOOKINGS_ALL,
      redirect: { name: RouteName.ADMIN_BOOKINGS_LIST },
      children: [
        {
          path: 'list',
          component: AdminBookingPageList,
          name: RouteName.ADMIN_BOOKINGS_LIST,
          meta: {
            breadcrumb: 'Все брони'
          }
        },
        {
          path: 'new',
          component: AdminBookingPageNew,
          name: RouteName.ADMIN_BOOKINGS_NEW,
          meta: {
            breadcrumb: 'Новая бронь'
          }
        }
      ]
    },
    {
      path: 'places',
      component: PlacesPage,
      name: RouteName.ADMIN_PLACES,
      meta: {
        breadcrumb: 'Управление местами'
      }
    },
    {
      path: 'cards',
      component: AdminBookingParkingCards,
      name: RouteName.ADMIN_CARDS,
      meta: {
        breadcrumb: 'Парковочная карта'
      }
    }
  ]
};
