import Keycloak from 'keycloak-js';

interface UseKeycloak {
  keycloak: Keycloak;
  isAuthenticated: boolean;
}

export const useKeycloak = async (): Promise<UseKeycloak> => {
  const keycloak = new Keycloak({
    url: import.meta.env.VITE_KEYCLOAK_ENDPOINT,
    realm: import.meta.env.VITE_KEYCLOAK_REALM,
    clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID
  });

  const isAuthenticated = await keycloak.init({
    onLoad: 'check-sso',
    checkLoginIframe: false
  });

  return { keycloak, isAuthenticated };
};
